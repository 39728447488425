import global from '@/store/global.js'
export default [
    // {
    //     title: "الرئيسية",
    //     icon: "fi fi-rr-apps",
    //     to: "/home",
    //     role: [global.state.roleType.investor],
    // },
    {
        title: "غلاف الصفحة",
        icon: "fi fi-rr-desktop-wallpaper",
        to: "/cover",
        role: [global.state.roleType.admin],
    },
    {
        title: "نشرة العيادات",
        icon: "fi fi-rr-blog-text",
        to: "/sliderClinics",
        role: [global.state.roleType.admin ],
    },
    {
        title: "العيادات",
        icon: "fi fi-rr-house-medical",
        to: "/clinic",
        role: [global.state.roleType.admin , global.state.roleType.employee],
    },
    {
        title: "الاطباء",
        icon: "fi fi-rr-user-md",
        to: "/doctor",
        role: [global.state.roleType.admin],
    },
    {
        title: "الاخبار",
        icon: "fi fi-rr-blog-text",
        to: "/post",
        role: [global.state.roleType.admin],
    },
    {
        title: "الاقسام",
        icon: "fi fi-rr-hospital",
        to: "/department",
        role: [global.state.roleType.admin],
    },
    {
        title: "الاجهزة",
        icon: "fi fi-rr-microscope",
        to: "/device",
        role: [global.state.roleType.admin],
    },
    {
        title: "الرسائل",
        icon: "fi fi-rr-inbox",
        to: "/inbox",
        role: [global.state.roleType.admin , global.state.roleType.employee],
    },
    {
        title: "الحجوزات",
        icon: "fi fi-rr-calendar-clock",
        to: "/reservationReview",
        role: [global.state.roleType.admin , global.state.roleType.employee],
    },
    {
        title: "العدادات",
        icon: "fi fi-rr-dashboard",
        to: "/counters",
        role: [global.state.roleType.admin],
    },
];
