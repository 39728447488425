<template>
<v-list :key="$vuetify.theme.dark+$route.path" dense>
    <v-list-group v-model="item.isOpen" :class="($route.path == item.to ? 'tab' : '')" v-for="(item, index) in tabs" :key="index" @click="item.to == $route.path ? '' : route(item.to)" :append-icon="item.childrens ? 'keyboard_arrow_down' : ''" v-show="checkAuth(item)">
        <template v-slot:activator>
            <v-list-item :class="$route.path != item.to ? 'navbar__item' : ''">
                <v-list-item-title :class="$route.path == item.to ? 'white--text mt-3' : item.childrens ? 'navbar__link_2' : 'navbar__link'">
                    <i :class="item.icon" style="font-size: 18px; font-style: normal; "></i>
                    <span style="font-size: 18px; font-style: normal;" class="mr-6">{{ item.title }}</span>
                </v-list-item-title>

            </v-list-item>
        </template>
        <v-divider />
        <v-list-item  v-for="(children, i) in item.childrens" :key="i" :to="children.to == $route.path ? '' : children.to"  :class="$route.path != children.to ? 'navbar__item' : ''">
            <!-- <v-list-item-icon> -->
            <!-- <v-icon class="white--text">{{ children.icon }}</v-icon> -->
            <!-- <v-avatar tile class="ml-3" size="25">
                    <v-img contain="" :src="children.icon" dark />
                </v-avatar> -->
            <!-- </v-list-item-icon> -->
            <v-list-item-title @click="currentChild = children.to" :class="$route.path == children.to ? 'primary--text mr-3' : 'navbar__link_2'">
                <i :class="children.icon" style="font-size: 18px; font-style: normal; "></i>
                <span class="mr-4" style="font-size: 18px; font-style: normal">{{
            children.title
          }}</span>
            </v-list-item-title>
        </v-list-item>
        <!-- <v-divider /> -->
    </v-list-group>
</v-list>
</template>

<script>
import tabs from './navigation'
export default {
    data() {
        return {
            tabs: [
                ...tabs
            ],
            currentChild: 'tt'
        }
    },

    methods: {
        route(to) {
            if (to) this.$router.push(to);
        },

        created() {
            this.tabs.find(x => x.to == this.$route.path)

        },

        checkAuth(item) {
            return item.role.includes(this.$store.state.user.userType.id);
        },
    },
};
</script>

<style lang="scss">
@import "@/style/variabels.scss";
$borderRadius: 10px;
$spacer: 1rem;
$primary: $primary-color;
$text: #6a778e;
$linkHeight: $spacer * 3.5;
$timing: 250ms;
$transition: $timing ease all;

@mixin gooeyEffect($i) {
    @keyframes gooeyEffect-#{$i} {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(0.5, 1.5);
        }

        100% {
            transform: scale(1, 1);
        }
    }
}

.navbar {
    $ref: &;
    position: fixed;
    top: $spacer;
    left: $spacer;
    background: #fff;
    border-radius: $borderRadius;
    padding: $spacer 0;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.03);
    height: calc(100vh - #{$spacer*4});

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        // justify-content: center;
        height: $linkHeight;
        width: $spacer * 5.5;
        color: $text;
        transition: $transition;
        font-weight: bold;

        // span {
        //     position: absolute;
        //     left: 100%;
        //     transform: translate(-($spacer*3));
        //     margin-left: 1rem;
        //     opacity: 0;
        //     pointer-events: none;
        //     color: $primary;
        //     background: #fff;
        //     padding: $spacer *0.75;
        //     transition: $transition;
        //     border-radius: $borderRadius * 1.75;
        // }

        &:hover {
            color: $primary;
            padding-right: 30px;

        }

        .navbar:not(:hover) &:focus,
        &:hover {
            span {
                opacity: 1;
                transform: translate(0);
            }
        }
    }

    &__link_2 {
        position: relative;
        display: flex;
        align-items: center;
        //justify-content: center;
        height: $linkHeight;
        width: $spacer * 5.5;
        color: $text;
        transition: $transition;
        font-weight: bold;

        // span {
        //     position: absolute;
        //     left: 100%;
        //     transform: translate(-($spacer*3));
        //     margin-left: 1rem;
        //     opacity: 0;
        //     pointer-events: none;
        //     color: $primary;
        //     background: #fff;
        //     padding: $spacer *0.75;
        //     transition: $transition;
        //     border-radius: $borderRadius * 1.75;
        // }

        &:hover {
            color: $primary-color;
            padding-right: 30px;

        }

        .navbar:not(:hover) &:focus,
        &:hover {
            span {
                opacity: 1;
                transform: translate(0);
            }
        }
    }

    &__menu {
        position: relative;
    }

    &__item {
        &:last-child {
            &:before {
                content: '';
                position: absolute;
                opacity: 0;
                z-index: -1;
                // top: 0;
                // left: $spacer;
                width: 300px;
                height: $linkHeight;
                color: $primary;
                border-radius: $borderRadius * 1.75;
                transition: $timing cubic-bezier(1, 0.2, 0.1, 1.2) all;
            }
        }

        @for $i from 1 to 12 {

            &:first-child:nth-last-child(#{$i}),
            &:first-child:nth-last-child(#{$i})~li {
                &:hover {
                    ~li:last-child:before {
                        opacity: 1;
                    }
                }

                &:last-child:hover:before {
                    opacity: 1;
                }

                @for $j from 1 to $i {

                    &:nth-child(#{$j}):hover,
                        {
                        ~li:last-child:before {
                            @include gooeyEffect($j);
                            top: (100% / $i) * ($j - 1);
                            animation: gooeyEffect-#{$j} $timing 1;
                        }
                    }
                }

                &:last-child:hover:before,
                    {
                    @include gooeyEffect($i);
                    top: (100% / $i) * ($i - 1);
                    animation: gooeyEffect-#{$i} $timing 1;
                }
            }
        }

    }

}
</style>
